import React, {useCallback, useEffect} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {useCustomer} from '../../contexts/CustomerContext';
import {ROUTES} from '../../constants/routes';
import { toast } from 'react-toastify';

const CustomerRoutes = () => {
    const {refreshInfos} = useCustomer();

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        updateList();
    }, [params.slug])


    const updateList = useCallback(() => {

        if (params.slug === ":slug") return;
        if (params.slug) {
            refreshInfos(params.slug).then((isGood) => {
                if (!isGood.success) {
                    toast.error(isGood.error ?? "Une erreur s'est produite lors de la récupération des informations de la campagne");
                    navigate(ROUTES.CHOOSE);
                }
            }).catch((error) => {
                toast.error(error.message ?? "Une erreur s'est produite lors de la récupération des informations de la campagne");
                navigate(ROUTES.CHOOSE);
            });
        } else {
            const location = window.location.href;
            if (!location.includes("termsofuse")) {
                navigate(ROUTES.CHOOSE);
            }
        }
    }, [params.slug]);

    return (
        <>
            <div className="min-h-screen">
                <Outlet/>
            </div>
        </>
    );
};

export default CustomerRoutes;

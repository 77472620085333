import React, { useContext, useEffect, useState } from 'react';
import {
  Campaign,
  Influencer,
  InfluencerList,
  InfluencerListInfluencerState,
} from '../types/dbModels';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { toast } from 'react-toastify';

type CustomerContextType = {
  refreshInfos: (slug: string) => Promise<{ success: boolean; error?: any }>;
  influencerList: InfluencerList | undefined;
  campaign: Campaign | undefined;
  isAuth: boolean;
  setIsAuth: (value: boolean) => void;
  influencers: Influencer[];
  influencersByCategory: any;
  customerLoading: CustomerLoading;
  updateCustomerLoading: (key: keyof CustomerLoading, value: boolean) => void;
  changeInfluencerState: (
    slug: string,
    influencerState: InfluencerListInfluencerState,
    state: 'pending' | 'accepted' | 'loved' | 'rejected'
  ) => void;
};

type CustomerLoading = {
  influencer: boolean;
  like: boolean;
};

const CustomerContext = React.createContext<CustomerContextType | null>(null);

export function useCustomer() {
  return useContext(CustomerContext) as CustomerContextType;
}

export function CustomerProvider({ children }: any) {
  const { getInfluencersFromIds, putInfluencerList } = useFirebase();

  const [influencerList, setInfluencerList] = useState<InfluencerList>();
  const [campaign, setCampaign] = useState<Campaign>();
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [influencers, setInfluencers] = useState<Influencer[]>([]);
  const [influencersByCategory, setInfluencerByCategory] = useState<any>({});

  // const [influencersLoading, setInfluencerLoading] = useState(false);
  const [customerLoading, setCustommerLoading] = useState<CustomerLoading>({
    influencer: false,
    like: false,
  });

  // UseEffects
  useEffect(() => {
    refreshInfluencers();
  }, [influencerList]);

  // Methods
  const updateCustomerLoading = (
    key: keyof CustomerLoading,
    value: boolean
  ) => {
    setCustommerLoading((loading) => {
      return {
        ...loading,
        [key]: value,
      };
    });
  };

  const refreshInfos = async (
    slug: string
  ): Promise<{ success: boolean; error?: any }> => {
    try {
      const requestedInfluencerListResponse = await getInfluencerListBySlug(
        slug
      );
      const influencerListDB =
        requestedInfluencerListResponse.data as InfluencerList;

      if (!requestedInfluencerListResponse.valid) {
        toast.error(
          requestedInfluencerListResponse.error ??
            "Une erreur s'est produite sur la liste"
        );
        return {
          success: false,
          error:
            requestedInfluencerListResponse.error ??
            "Une erreur s'est produite sur la liste",
        };
      }

      if (!influencerListDB) {
        return {
          success: false,
          error: "La liste d'influenceurs n'existe pas",
        };
      }
      setInfluencerList(influencerListDB);

      const requestedCampaignResponse = await refreshCampaign(
        influencerListDB.id
      );
      const campaignDB = requestedCampaignResponse.data as Campaign;

      if (!requestedCampaignResponse.valid) {
        setCampaign(undefined);
        toast.error(
          requestedCampaignResponse.error ??
            "Une erreur s'est produite sur la campagne"
        );
        return {
          success: false,
          error:
            requestedCampaignResponse.error ??
            "Une erreur s'est produite sur la campagne",
        };
      }

      if (campaignDB) {
        setCampaign(campaignDB);
      }

      return {
        success: true,
      }; 
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la récupération des informations de la campagne");
      return {
        success: false,
        error: error ?? "Une erreur s'est produite lors de la récupération des informations de la campagne",
      };
    }
  };

  const getInfluencerListBySlug = async (slug: string) => {
    try {
      const q = query(
        collection(db, 'Influencer_Lists'),
        where('link', '==', slug)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.error("La liste n'existe pas");
        return {
          valid: false,
          data: null,
          error: "La liste n'existe pas",
        };
      }

      const listSnapshot = querySnapshot.docs[0];

      return {
        valid: true,
        data: {
          id: listSnapshot.id,
          ...listSnapshot.data(),
        } as InfluencerList,
        error: '',
      };
    } catch (error) {
      console.error("Une erreur s'est produite :", error);
      return {
        valid: false,
        data: null,
        error: '',
      };
    }
  };

  const changeInfluencerState = (
    slug: string,
    influencerState: InfluencerListInfluencerState,
    state: 'pending' | 'accepted' | 'loved' | 'rejected'
  ) => {
    if (!influencerState || !slug || !influencerList) return;

    const categories = [...influencerList.influencersCategories];

    let influencer: any = categories
      .map((c) =>
        c.influencers.find((i) => i.userId === influencerState.userId)
      )
      .filter((i) => i);
    if (influencer.length === 0) return;
    influencer = influencer[0];
    influencer.state = state !== influencerState.state ? state : 'pending';

    putInfluencerList(influencerList.id, { influencersCategories: categories })
      .then(() => {
        return refreshInfos(slug);
      })
      .then((response) => {
        if (response.success) {
          toast.success('Statut de la liste mis à jour');
        } else {
          toast.error(response.error ?? 'Une erreur s\'est produite lors de la récupération des informations de la campagne');
        }
      })
      .catch(() => {
        toast.error(
          'La mise à jour du statut a échoué, veuillez réessayer ultérieurement'
        );
      });
  };

  const refreshInfluencers = async (shouldLoading: boolean = true) => {
    // shouldLoading && updateCustomerLoading('influencer', true)
    if (!influencerList) {
      setInfluencerByCategory({});
      setInfluencers([]);
      // updateCustomerLoading('influencer', false)
      return;
    }

    const allInfluencerIds = influencerList.influencersCategories
      .map((c) => c.influencers)
      .flat()
      .filter((state) => state.visible)
      .map((i) => i.userId);
    const influencersData = await getInfluencersFromIds(allInfluencerIds);

    const _influenersByCategory: any = {};

    for (const influencersCategory of influencerList.influencersCategories) {
      const ids = influencersCategory.influencers.map((i) => i.userId);
      _influenersByCategory[influencersCategory.id] = influencersData.filter(
        (i) => ids.includes(i.userId)
      );
    }

    setInfluencers(influencersData);
    setInfluencerByCategory(_influenersByCategory);
    // shouldLoading && updateCustomerLoading('influencer', false)
  };

  const refreshCampaign = async (id: string) => {
    try {
      const q = query(collection(db, 'Campaigns'), where('list', '==', id));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return {
          valid: true,
          data: null,
        };
      }

      const campaignSnapchot = querySnapshot.docs[0];

      return {
        valid: true,
        data: {
          id: campaignSnapchot.id,
          ...campaignSnapchot.data(),
        } as Campaign,
        error: '',
      };
    } catch (error) {
      return {
        valid: false,
        data: null,
        error: '',
      };
    }
  };

  const updateIsAuth = (value: boolean) => {
    setIsAuth(value);
  };

  const value = {
    influencerList,
    campaign,
    refreshInfos,
    isAuth,
    setIsAuth: updateIsAuth,
    influencers,
    influencersByCategory,
    customerLoading,
    updateCustomerLoading,
    changeInfluencerState,
  };

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
}

import React, { useState, useEffect } from 'react';
import { ListFormData } from '../../types/formData';
import { useFirebase } from '../../contexts/FirebaseContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SelectNetwork from '../SelectNetwork';
import FormInput from '../FormInput';
import { MultiElementSelect } from '../select/MultiElementSelect';
import { MultiSelect } from '../select/MultiSelect';

type Props = {
  name: string;
  onResponse: (valid: boolean, response: any) => any;
  setLoading?: (value: boolean) => any;
};

export default function CreateListModal({
  name,
  onResponse,
  setLoading,
}: Props) {
  const {
    getListsLabels,
    postInfluencerList,
    getInfluencerListAvailableMarkets,
  } = useFirebase();

  const { t } = useTranslation();

  const [availableMarketValues, setAvailableMarketValues] = useState<any[]>(
    getInfluencerListAvailableMarkets()
  );

  const [formData, setFormData] = useState<ListFormData>({
    network: 'instagram',
    campaign: '',
    labels: [],
    market: [],
    name: '',
    password: '',
    banner: '',
    link: '',
    influencersCategories: [],
    isTemplate: false,
    isArchived: false,
    poNumber: '',
  });

  const [labels, setLabels] = useState<{
    label1: string;
    label2: string;
    label3: string;
  }>({
    label1: '-',
    label2: '-',
    label3: '-',
  });

  const [industry, setIndustry] = useState<string>('');

  const [splittedName, setSplittedName] = useState<{
    brand: string;
    campaign: string;
    year: string;
  }>({
    brand: '',
    campaign: '',
    year: '',
  });

  useEffect(() => {
    setFormData((old) => {
      const newLabels = [];
      for (const value of Object.values(labels)) {
        if (value) {
          newLabels.push(value);
        }
      }

      return {
        ...old,
        labels: newLabels,
      };
    });
  }, [labels]);

  useEffect(() => {
    setFormData((old) => {
      return {
        ...old,
        name: `${
          formData.isTemplate
            ? industry.toUpperCase()
            : splittedName.brand.toUpperCase()
        }_${splittedName.campaign
          .charAt(0)
          .toUpperCase()}${splittedName.campaign.slice(1).toLowerCase()}_${
          splittedName.year
        }`,
      };
    });
  }, [splittedName]);

  useEffect(() => {
    if (!formData.isTemplate) {
      setIndustry('');
    } else {
      setSplittedName((old) => ({
        ...old,
        brand: '',
      }));
    }
  }, [formData.isTemplate]);

  useEffect(() => {
    if (formData.isTemplate) {
      setSplittedName((old) => ({
        ...old,
        name: industry,
      }));
    }
  }, [industry]);

  const inputChangeHandler = (event: any) => {
    const { name, value, type, checked } = event;

    setFormData((old) => ({
      ...old,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const labelChangeHandler = (event: any) => {
    const { name, value } = event.target;
    setLabels((old) => ({ ...old, [name]: value }));
  };

  const industryChangeHandler = (event: any) => {
    setIndustry(event.target.value);
  };

  const splittedNameChangeHandler = (event: any) => {
    const { name, value } = event.target;
    setSplittedName((old) => ({ ...old, [name]: value }));
  };

  const marketChangerHandler = (newValue: string[]) => {
    setFormData((old) => ({ ...old, market: newValue }));
  };

  const submitHandler = (e: any) => {
    for (const [key, value] of Object.entries(formData)) {
      if (
        !value &&
        key !== 'campaign' &&
        key !== 'isTemplate' &&
        key !== 'isArchived' &&
        key !== 'banner' &&
        key !== 'link' &&
        key !== 'poNumber'
      ) {
        e.preventDefault();
        toast.warning(t('other.fill_all_fields'));
        return;
      }
    }

    if (
      (!formData.isTemplate && !splittedName.brand) ||
      !splittedName.campaign ||
      !splittedName.year ||
      (formData.isTemplate && (!industry || industry == '-'))
    ) {
      e.preventDefault();
      toast.warning(t('other.enter-all-name-fields'));
      return;
    }

    // Création de la liste
    if (setLoading) {
      setLoading(true);
    }
    postInfluencerList(formData)
      .then((r: any) => {
        toast.success(t('list.add_success'));
        onResponse(true, r);
      })
      .catch((error) => {
        alert(error);
        toast.error(t('list.add_error'));
        onResponse(false, null);
      })
      .finally(() => {
        if (setLoading) {
          setLoading(false);
        }
        resetForm();
      });
  };

  const resetForm = () => {
    setFormData({
      network: 'instagram',
      campaign: '',
      labels: [],
      market: [],
      name: '',
      password: '',
      banner: '',
      link: '',
      influencersCategories: [],
      isTemplate: false,
      isArchived: false,
    });
    setLabels({
      label1: '-',
      label2: '-',
      label3: '-',
    });

    setSplittedName({
      brand: '',
      campaign: '',
      year: '',
    });

    setIndustry('');
  };

  return (
    <div>
      <dialog id={name} className="modal">
        <form method="dialog" className="modal-box w-11/12 max-w-5xl">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
          <h3 className="font-bold text-lg mb-8">{t('list.new')}</h3>

          <div className="flex justify-between">
            <FormInput
              onChange={inputChangeHandler}
              name={'isTemplate'}
              type={'checkbox'}
              label={t('template')}
              checked={formData?.isTemplate}
            />
            <SelectNetwork
              value={formData['network']}
              onChange={(newValue) =>
                inputChangeHandler({
                  name: 'network',
                  value: newValue,
                })
              }
            />
          </div>

          <div className="grid grid-cols-2 gap-8 my-4">
            <div className="grid grid-cols-3 gap-2">
              {formData.isTemplate ? (
                <FormInput
                  type="select"
                  onChange={industryChangeHandler}
                  name={'industry'}
                  value={industry}
                  label={t('list.tab-general.industry')}
                  defaultMinWidth={false}
                >
                  {[
                    { id: -1, text: '-' },
                    { id: 'food-beverages', text: t('list.tab-general.industries.food-beverages') },
                    { id: 'newcomers', text: t('list.tab-general.industries.newcomers') },
                    { id: 'fintech', text: t('list.tab-general.industries.fintech') },
                    { id: 'automobile-mobility', text: t('list.tab-general.industries.automobile-mobility') },
                    { id: 'travel', text: t('list.tab-general.industries.travel') },
                    { id: 'fashion', text: t('list.tab-general.industries.fashion') },
                    { id: 'health-wellness', text: t('list.tab-general.industries.health-wellness') },
                    { id: 'home-decor', text: t('list.tab-general.industries.home-decor') },
                    { id: 'institutions-government', text: t('list.tab-general.industries.institutions-government') },
                    { id: 'science-clarifiers', text: t('list.tab-general.industries.science-clarifiers') },
                    { id: 'moms', text: t('list.tab-general.industries.moms') },
                    { id: 'dad', text: t('list.tab-general.industries.dad') },
                    { id: 'comedy', text: t('list.tab-general.industries.comedy') },
                    { id: 'art', text: t('list.tab-general.industries.art') },
                    { id: 'vox-pop', text: t('list.tab-general.industries.vox-pop') },
                    { id: 'alcohol', text: t('list.tab-general.industries.alcohol') },
                    { id: 'sport', text: t('list.tab-general.industries.sport') },
                    { id: 'senior', text: t('list.tab-general.industries.senior') },
                  ]
                    .sort((a, b) => t(a.text).localeCompare(t(b.text)))
                    .map((label: any) => (
                      <option key={label.id} id={label.id} value={label.text}>
                        {t(label.text)}
                      </option>
                    ))}
                </FormInput>
              ) : (
                <FormInput
                  onChange={splittedNameChangeHandler}
                  value={splittedName['brand']}
                  name={'brand'}
                  placeholder={t('list.tab-general.name-brand')}
                  label={t('list.tab-general.name-brand')}
                />
              )}

              <FormInput
                onChange={splittedNameChangeHandler}
                value={splittedName['campaign']}
                name={'campaign'}
                placeholder={t('list.tab-general.name-campaign')}
                label={t('list.tab-general.name-campaign')}
              />
              <FormInput
                onChange={splittedNameChangeHandler}
                value={splittedName['year']}
                name={'year'}
                placeholder={t('list.tab-general.name-year')}
                label={t('list.tab-general.name-year')}
              />
            </div>

            <FormInput
              type="password"
              onChange={inputChangeHandler}
              value={formData['password']}
              name="password"
              placeholder={t('labels.password')}
              label={t('labels.password')}
            />
          </div>

          <div className="grid grid-cols-3 gap-4 my-4">
            <FormInput
              type="select"
              onChange={labelChangeHandler}
              name={'label1'}
              value={labels['label1']}
              label={t('labels.label') + ' #1'}
            >
              {getListsLabels()
                .sort((a, b) => t(a.text).localeCompare(t(b.text)))
                .map((label: any) => (
                  <option key={label.id} id={label.id} value={label.id}>
                    {t(label.text)}
                  </option>
                ))}
            </FormInput>
            <FormInput
              type="select"
              onChange={labelChangeHandler}
              name={'label2'}
              value={labels['label2']}
              label={t('labels.label') + ' #2'}
            >
              {getListsLabels()
                .sort((a, b) => t(a.text).localeCompare(t(b.text)))
                .map((label: any) => (
                  <option key={label.id} id={label.id} value={label.id}>
                    {t(label.text)}
                  </option>
                ))}
            </FormInput>
            <FormInput
              type="select"
              onChange={labelChangeHandler}
              name={'label3'}
              value={labels['label3']}
              label={t('labels.label') + ' #3'}
            >
              {getListsLabels()
                .sort((a, b) => t(a.text).localeCompare(t(b.text)))
                .map((label: any) => (
                  <option key={label.id} id={label.id} value={label.id}>
                    {t(label.text)}
                  </option>
                ))}
            </FormInput>
          </div>

          <hr className={'my-2'} />

          <div className="flex items-end gap-4">
            <div className="w-1/3">
              <FormInput
                onChange={inputChangeHandler}
                value={formData['poNumber']}
                name={'poNumber'}
                placeholder={t('list.tab-general.po-number')}
                label={t('list.tab-general.po-number')}
              />
            </div>
            <div className="flex-1">
              <MultiElementSelect
                label={t('list.market.label')}
                values={availableMarketValues}
                onSelectedValueChanged={marketChangerHandler}
                selectedValues={formData.market.map((v) =>
                  availableMarketValues.find((tmp) => tmp.id === v)
                )}
              />
            </div>
          </div>

          <div className="flex justify-center mt-3">
            <button className="btn btn-primary mr-8" onClick={submitHandler}>
              {t('list.create')}
            </button>
          </div>
        </form>
      </dialog>
    </div>
  );
}

import React, { useEffect, useState } from 'react';

import SimpleDataChart from '../chart/SimpleDataChart';
import { Box, Meter, Stack, Text, DataChart } from 'grommet';
import { useApi } from '../../contexts/ApiContext';
import { CommentType, InfluencerList, Networks } from '../../types/dbModels';
import { InfluencerReport } from '../../types/api';
import { useFirebase } from '../../contexts/FirebaseContext';
import {
  formatNumber,
  formatPercentage,
  capitalizeFirstLetter,
  countryCodeToFlag,
  mediaTypeToEmoji,
} from '../../helpers/functions';
import { useTranslation } from 'react-i18next';
import ProgressChart from '../chart/ProgressChart';
import InlineTextChart from '../chart/InlineTextChart';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import generatePDF, { Margin } from 'react-to-pdf';
// Images
import imgComment from '../../assets/images/emojis/comment.png';
import imgPlay from '../../assets/images/emojis/play.png';
import imgThumbUp from '../../assets/images/emojis/thump.png';
import imgPicture from '../../assets/images/emojis/picture.png';
import Loading from '../loading/Loading';
import emptyBox from '../../assets/images/empty.png';
import emptyFile from '../../assets/images/emptyfile.png';
import imgVerified from '../../assets/images/verified.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormInput from '../FormInput';
import StateJudging from '../customer/StateJudging';
import chartLogo from '../../assets/images/charte-ethique.png';

const PDFOptions = {
  method: 'save',
  page: {
    margin: Margin.SMALL,
  },
  canvas: {},
};

type Props = {
  name: string;
  influencerId: string;
  platform: Networks;
  getFromFirebase?: boolean;
  manageProfilAsClark?: boolean;
  influencerList?: InfluencerList;
  showInModal?: boolean;
  showInCustomerViewMode: boolean;
  allowReload?: boolean;
  onAddComment?: (comment: CommentType) => void;
  showJudging?: boolean;
  shouldBlur?: boolean;
  comments?: CommentType[];
  onCommentButtonClick?: () => any;
};

export default function InfluencerSearchDetailsModal({
  name,
  influencerId,
  platform,
  getFromFirebase = false,
  influencerList,
  showInModal = true,
  showInCustomerViewMode = false,
  allowReload = false,
  showJudging = false,
  shouldBlur = false,
  comments,
  onCommentButtonClick,
}: Props) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const { getReport } = useApi();
  const { getInfluencer, putInfluencer } = useFirebase();

  const [tabSelected, setTabSelected] = useState(0);
  const [influencerReport, setInfluencerReport] =
    useState<InfluencerReport | null>();
  const [disabledRefreshDataBtn, setDisabledRefreshDataBtn] =
    useState<boolean>(false);
  const [signedCodeOfEthics, setSignedCodeOfEthics] = useState<boolean>(false);

  // Content tab
  const [contentCategory, setContentCategory] = useState<string>('famous');
  const [postsToDisplay, setPostsToDisplay] = useState<any[]>([]);

  const testRef = useRef();
  const [isPDF, setIsPDF] = useState<boolean>(false);

  useEffect(() => {
    if (isPDF) {
      const filenamePdf =
        'profile-' +
        influencerReport?.profile.userId +
        '-export-' +
        Date.now() +
        '.pdf';
      generatePDF(testRef, {
        ...PDFOptions,
        filename: filenamePdf,
        method: 'save',
      }).finally(() => setIsPDF(false));
    }
  }, [isPDF]);

  useEffect(() => {
    if (influencerReport) {
      // Set default tab (stats)
      setTabSelected(0);

      // Set default content category (famous posts)
      setContentCategory('famous');
      setPostsToDisplay(influencerReport?.profile?.popularPosts || []);
    }
  }, [influencerReport]);

  useEffect(() => {
    if (influencerId) {
      getInfluencer(influencerId).then((i) => {
        setSignedCodeOfEthics(i?.signedCodeOfEthics || false);
      });
    }
  }, [influencerId]);

  useEffect(() => {
    setInfluencerReport(null);
    setLoading(true);
    if (!influencerId) return;

    if (!getFromFirebase) {
      getReport(influencerId, platform).then((response) => {
        setInfluencerReport(response);
        setLoading(false);
      });
    } else {
      getInfluencer(influencerId).then((influencer) => {
        if (influencer) {
          setInfluencerReport({
            error: false,
            profile: influencer[platform] as any,
          });
        }

        setLoading(false);
      });
    }
  }, [influencerId, platform]);

  const refreshData = async () => {
    if (!influencerReport || !getFromFirebase || !platform) return;

    // Disabled the button for 5 seconds
    setDisabledRefreshDataBtn(true);
    setTimeout(() => setDisabledRefreshDataBtn(false), 5000);

    const firebaseInfluencer = await getInfluencer(
      influencerReport.profile.userId
    );

    if (firebaseInfluencer) {
      const newReport = await getReport(influencerId, platform);
      let influencerName =
        influencerReport?.profile.profile.fullname ||
        influencerReport?.profile.profile.handle ||
        influencerReport?.profile.profile.username;
      if (newReport) {
        await putInfluencer(newReport.profile.userId, {
          [platform]: newReport.profile,
        });
        setInfluencerReport(newReport);
        toast.success(t('data-of') + influencerName + t('data-of-end'));
      } else {
        toast.error(t('error-update-influencer-data') + influencerName);
      }
    }
  };

  const updateSignedCodeOfEthics = async (value: boolean) => {
    const shouldUpdate = value !== signedCodeOfEthics;
    setSignedCodeOfEthics(value);

    if (shouldUpdate) {
      try {
        await putInfluencer(influencerId, {
          signedCodeOfEthics: !signedCodeOfEthics,
        });
        toast.success(t('data-updated'));
      } catch (err) {
        toast.error(t('error-while-updated'));
      }
    }
  };

  const renderCardSimilarProfile = (index: number, profile: any) => {
    return (
      <div
        key={'card-profile-' + index}
        className="card w-64 bg-base-100 hand shadow-md"
        onClick={() => {
          window.open(profile.url, '_blank');
        }}
      >
        {profile.picture && (
          <img
            className="max-h-36 h-36 imgCardInfluencer"
            src={profile.picture}
            alt={'Profile similar'}
            height={50}
            style={{ objectFit: 'cover' }}
          />
        )}
        <div className="card-body">
          <h2 className="card-title">{profile.fullname}</h2>
          <h2>
            {profile.likes && (
              <div className="badge badge-ghost m-1">
                {'📈 ' + formatNumber(profile.followers)}
              </div>
            )}
          </h2>
        </div>
      </div>
    );
  };

  const renderCardPosts = (index: number, post: any) => {
    let postTitle = capitalizeFirstLetter(post.type || post.text);
    if (postTitle && postTitle.length > 50)
      postTitle = postTitle.slice(0, 50) + '...';

    return (
      <div
        key={'card-post-' + index}
        className="card w-64 bg-base-100 hand shadow-md"
        onClick={() => {
          window.open(post.url, '_blank');
        }}
      >
        {(post.thumbnail || post.image) && (
          <img
            className="max-h-36 h-36 imgCardInfluencer"
            src={post.thumbnail || post.image}
            alt={'Preview post'}
            height={50}
            style={{ objectFit: 'cover' }}
          />
        )}
        <div className="card-body">
          <h2 className="card-title">
            {mediaTypeToEmoji(post.type || '') + ' ' + (postTitle || '')}
          </h2>
          <h2>
            {post.likes && (
              <div className="badge badge-ghost m-1">
                {'❤️ ' + formatNumber(post.likes)}
              </div>
            )}
            {post.comments && (
              <div className="badge badge-ghost m-1">
                {'💬 ' + formatNumber(post.comments)}
              </div>
            )}
            {post.views && (
              <div className="badge badge-ghost m-1">
                {'👀 ' + formatNumber(post.views)}
              </div>
            )}
          </h2>
          <p>{post?.text?.slice(0, 50)}</p>
        </div>
      </div>
    );
  };

  const renderInfluencerData = (influencerReport: InfluencerReport) => {
    return (
      <>
        <div ref={testRef as any}>
          <div
            className="first-part"
            style={{ height: isPDF ? '610px' : 'auto' }}
          >
            {/* HEADER: picture, name, username */}
            <div className="flex flex-row gap-6">
              <div className="basis-1/3 indicator position-relative">
                {influencerReport.profile?.isVerified && (
                  <span className={`indicator-item ${isPDF ? 'indicator-item-pdf' : ''}`}>
                    <img
                      src={imgVerified}
                      alt="profile-verified-badge"
                      width={32}
                      height={32}
                    />
                  </span>
                )}
                <img
                  className="rounded-xl imgCover"
                  src={influencerReport.profile?.profile?.picture}
                  alt="Influencer profile pic"
                />
                {signedCodeOfEthics && (
                  <div className="place-bottom-left">
                    <img
                      src={chartLogo}
                      alt="Ethic chart logo"
                      width={100}
                      height={100}
                    />
                  </div>
                )}
              </div>
              <div className="basis-2/3">
                <div className="flex justify-between mb-4">
                  <div>
                    <h4 className="text-3xl font-bold">
                      {influencerReport?.profile?.profile?.fullname}
                    </h4>
                    {platform !== 'youtube' && (
                      <h6 className="text-sm">
                        @{influencerReport?.profile?.profile?.username}
                      </h6>
                    )}
                    {platform === 'youtube' && (
                      <h6 className="text-sm">
                        {influencerReport?.profile?.profile?.handle}
                      </h6>
                    )}
                  </div>
                </div>

                {(influencerReport?.profile?.bio ||
                  influencerReport?.profile?.description) && (
                  <div className="my-4">
                    <h6 className="text-sm mb-2 underline">
                      {t('labels.bio')}
                    </h6>
                    <h6 className="text-sm">
                      {influencerReport?.profile.bio ||
                        influencerReport?.profile?.description}
                    </h6>
                  </div>
                )}

                {influencerReport?.profile?.hashtags &&
                  influencerReport?.profile?.hashtags?.length > 0 && (
                    <div className="my-4">
                      <h6 className="text-sm mb-2 underline">
                        {t('labels.hashtags')}
                      </h6>
                      {influencerReport?.profile?.hashtags
                        .slice(0, 10)
                        .map((hashtag, index) => {
                          return (
                            <div key={index} className="badge badge-ghost m-1 items-center"  style={{ paddingBottom: isPDF ? '16px' : '0px' }}>
                              {hashtag.tag}
                            </div>
                          );
                        })}
                    </div>
                  )}
                {showJudging && (
                  <div className="flex justify-start items-center gap-4">
                    <StateJudging influencerId={influencerId} />
                    {comments !== undefined && (
                      <div className="border border-red-600 rounded-lg px-5 py-2 cursor-pointer">
                        <button
                          type="button"
                          className="nowrap text-xl text-red-700"
                          onClick={() => {
                            onCommentButtonClick && onCommentButtonClick();
                          }}
                        >
                          <span className="mr-2">💬</span>{' '}
                          {
                            (comments as CommentType[]).filter(
                              (obj) => !obj.internal
                            ).length
                          }
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {!isPDF && (
              <div className="flex flex-row flex-wrap my-4 items-center">
                {influencerReport.profile?.profile?.url && (
                  <button
                    className="btn btn-primary btn-md m-1"
                    onClick={() => {
                      window.open(
                        influencerReport.profile.profile.url,
                        '_blank'
                      );
                    }}
                    type={'button'}
                  >
                    <FontAwesomeIcon size="xl" icon={['fab', platform]} />
                    {capitalizeFirstLetter(platform)}
                  </button>
                )}

                {influencerReport.profile?.contacts && (
                  <>
                    {influencerReport.profile.contacts
                      .filter((c) => {
                        if (c.type === 'email' && showInCustomerViewMode)
                          return false;
                        return true;
                      })
                      .map((contact, index) => (
                        <button
                          key={'contact-' + index}
                          className="btn btn-md m-1"
                          onClick={() => {
                            let url = contact.value;
                            if (contact.type === 'email')
                              url = 'mailto:' + contact.value;
                            window.open(url, '_blank');
                          }}
                          type={'button'}
                        >
                          {capitalizeFirstLetter(contact.type)}
                        </button>
                      ))}
                  </>
                )}

                {allowReload && (
                  <>
                    <button
                      className="btn btn-md m-1 mr-2"
                      onClick={refreshData}
                      disabled={disabledRefreshDataBtn}
                      type={'button'}
                    >
                      <FontAwesomeIcon
                        size="xl"
                        icon={['fas', 'arrows-rotate']}
                      />
                      {t('labels.refresh-data-btn')}
                    </button>
                    <button
                      className="btn btn-md m-1 mr-2"
                      onClick={() => setIsPDF(true)}
                    >
                      <FontAwesomeIcon size="xl" icon={['fas', 'file-pdf']} />
                      {t('labels.export-pdf-btn')}
                    </button>
                    <button
                      className={
                        signedCodeOfEthics
                          ? 'btn btn-md m-1 mr-2 btn-secondary'
                          : 'btn btn-md m-1 mr-2'
                      }
                      onClick={() =>
                        updateSignedCodeOfEthics(!signedCodeOfEthics)
                      }
                      type={'button'}
                    >
                      <FontAwesomeIcon size="xl" icon={['fas', 'signature']} />
                      {signedCodeOfEthics
                        ? t('labels.has-signed-btn')
                        : t('labels.hasnt-signed-btn')}
                    </button>
                  </>
                )}
              </div>
            )}

            {influencerReport?.profile?.audience?.genders &&
              influencerReport?.profile?.audience?.genders.length > 1 &&
              influencerReport.profile?.audience?.geoCountries &&
              influencerReport.profile?.audience?.geoCountries.length > 0 && (
                <div
                  className="grid gap-8"
                  style={{ gridTemplateColumns: '1fr' }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                    }}
                  >
                    <p>
                      {t('influencer.intro-header-1')}
                      <b>{influencerReport?.profile?.profile?.fullname}</b>
                      {t('influencer.intro-header-2')}
                      <b>
                        {influencerReport.profile?.audience?.geoCountries[0]
                          .name || '?'}{' '}
                        (
                        {formatPercentage(
                          influencerReport.profile?.audience?.geoCountries[0]
                            .weight
                        )}
                        %)
                      </b>
                      ,
                    </p>
                    <p>
                      {t('influencer.intro-header-3')}
                      <b>
                        {influencerReport?.profile?.audience?.genders.reduce(
                          (max, obj) => (obj.weight > max.weight ? obj : max)
                        ).code === 'MALE'
                          ? t('labels.genders.male').toLowerCase()
                          : t('labels.genders.female').toLowerCase()}{' '}
                        (
                        {formatPercentage(
                          influencerReport?.profile?.audience?.genders.reduce(
                            (max, obj) => (obj.weight > max.weight ? obj : max)
                          ).weight
                        )}{' '}
                        %)
                      </b>
                      .{t('influencer.intro-header-4')}
                      <b>
                        {influencerReport.profile.audience?.ages &&
                          capitalizeFirstLetter(
                            influencerReport.profile.audience.ages.sort(
                              (a, b) => b.weight - a.weight
                            )[0]?.code || '-'
                          )}
                        {t('influencer.intro-header-5')}
                      </b>
                      .
                    </p>
                  </div>
                </div>
              )}

            {/* STATS: Followers & engagement rate */}
            <div className="grid grid-cols-3 gap-4 my-8">
              {influencerList?.settings?.onDetails?.showFollowers !== false &&
                influencerReport?.profile?.profile?.followers && (
                  <div className="flex my-4 justify-center">
                    <SimpleDataChart
                      title={`${formatNumber(
                        influencerReport.profile.profile.followers
                      )}`}
                      subTitle={t('labels.followers')}
                      theme={2}
                    />
                  </div>
                )}
              <div className="col-span-2">
                {!showInCustomerViewMode &&
                  influencerReport.profile?.accountType && (
                    <div className="grid grid-cols-3 gap-4">
                      <div className="text-sm flex self-center justify-end">
                        {t('labels.account_type')}
                      </div>
                      <div className="col-span-2 flex flex-row content-center self-center">
                        <div
                          className="text-sm content-center self-center font-bold overflow-hidden"
                          style={{ minHeight: isPDF ? '25px' : 'auto' }}
                        >
                          {capitalizeFirstLetter(
                            influencerReport.profile.accountType
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                {influencerReport.profile?.language?.name && (
                  <div className="grid grid-cols-3 gap-4">
                    <div className="text-sm flex self-center justify-end">
                      {t('labels.language')}
                    </div>
                    <div className="col-span-2 flex flex-row content-center self-center">
                      <div
                        className="text-sm content-center self-center font-bold overflow-hidden"
                        style={{ minHeight: isPDF ? '25px' : 'auto' }}
                      >
                        {countryCodeToFlag(
                          influencerReport.profile.language.code
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {influencerReport.profile?.country && (
                  <div className="grid grid-cols-3 gap-4">
                    <div className="text-sm flex self-center justify-end">
                      {t('labels.country')}
                    </div>
                    <div className="col-span-2 flex flex-row content-center self-center">
                      <div
                        className="text-sm content-center self-center font-bold overflow-hidden"
                        style={{ minHeight: isPDF ? '25px' : 'auto' }}
                      >
                        {countryCodeToFlag(influencerReport.profile.country)}
                      </div>
                    </div>
                  </div>
                )}

                {influencerReport.profile?.city && (
                  <div className="grid grid-cols-3 gap-4">
                    <div className="text-sm flex self-center justify-end">
                      {t('labels.city')}
                    </div>
                    <div className="col-span-2 flex flex-row content-center self-center">
                      <div
                        className="text-sm content-center self-center font-bold overflow-hidden"
                        style={{ minHeight: isPDF ? '25px' : 'auto' }}
                      >
                        {influencerReport.profile.city}
                      </div>
                    </div>
                  </div>
                )}
                {influencerReport.profile?.gender && (
                  <div className="grid grid-cols-3 gap-4">
                    <div className="text-sm flex self-center justify-end">
                      {t('labels.gender')}
                    </div>
                    <div className="col-span-2 flex flex-row content-center self-center">
                      <div
                        className="text-sm content-center self-center font-bold overflow-hidden"
                        style={{ minHeight: isPDF ? '25px' : 'auto' }}
                      >
                        {influencerReport.profile.gender === 'MALE'
                          ? t('labels.genders.male')
                          : t('labels.genders.female')}
                      </div>
                    </div>
                  </div>
                )}

                {influencerReport.profile.audience?.ages &&
                  influencerReport.profile?.ageGroup && (
                    <div className="grid grid-cols-3 gap-4">
                      <div className="text-sm flex self-center justify-end">
                        {t('labels.targetage')}
                      </div>
                      <div className="col-span-2 flex flex-row content-center self-center">
                        <div
                          className="text-sm content-center self-center font-bold overflow-hidden"
                          style={{ minHeight: isPDF ? '25px' : 'auto' }}
                        >
                          {influencerReport.profile.audience.ages.sort(
                            (a, b) => b.weight - a.weight
                          )[0]?.code || '-'}
                        </div>
                      </div>
                    </div>
                  )}
                {influencerReport.profile?.profile?.engagementRate && (
                  <div className="grid grid-cols-3 gap-4">
                    <div className="text-sm flex self-center justify-end">
                      {t('labels.engagement_rate')}
                    </div>
                    <div className="col-span-2 flex flex-row content-center self-center">
                      <div
                        className="text-sm content-center self-center font-bold overflow-hidden"
                        style={{ minHeight: isPDF ? '25px' : 'auto' }}
                      >
                        {formatPercentage(
                          influencerReport.profile.profile.engagementRate
                        ) + '%'}
                      </div>
                    </div>
                  </div>
                )}

                {influencerReport.profile?.audience?.credibility && !isPDF && (
                  <ProgressChart
                    value={formatPercentage(
                      influencerReport.profile.audience?.credibility
                    )}
                    label={t('labels.credibility')}
                    max={100}
                  />
                )}

                {influencerReport.profile?.audience?.credibility && isPDF && (
                  <div className="grid grid-cols-3 gap-4">
                    <div className="text-sm flex self-center justify-end">
                      {t('labels.credibility')}
                    </div>
                    <div className="col-span-2 flex flex-row content-center self-center">
                      <div
                        className="text-sm content-center self-center font-bold overflow-hidden"
                        style={{ minHeight: isPDF ? '25px' : 'auto' }}
                      >
                        {formatPercentage(
                          influencerReport.profile.audience?.credibility
                        ) + '%'}
                      </div>
                    </div>
                  </div>
                )}

                {influencerReport.profile?.paidPostPerformance && !isPDF && (
                  <ProgressChart
                    value={formatPercentage(
                      influencerReport.profile.paidPostPerformance
                    )}
                    label={t('labels.perfposts')}
                    max={100}
                  />
                )}

                {influencerReport.profile?.paidPostPerformance && isPDF && (
                  <div className="grid grid-cols-3 gap-4">
                    <div className="text-sm flex self-center justify-end">
                      {t('labels.perfposts')}
                    </div>
                    <div className="col-span-2 flex flex-row content-center self-center">
                      <div
                        className="text-sm content-center self-center font-bold overflow-hidden"
                        style={{ minHeight: isPDF ? '25px' : 'auto' }}
                      >
                        {formatPercentage(
                      influencerReport.profile.paidPostPerformance
                    ) + '%'}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {!isPDF && (
              <div className="tabs">
                <a
                  onClick={() => setTabSelected(0)}
                  className={
                    tabSelected == 0
                      ? 'tab tab-lg tab-bordered tab-active font-bold'
                      : 'tab tab-lg tab-bordered'
                  }
                >
                  {t('labels.stats')}
                </a>
                <a
                  onClick={() => setTabSelected(1)}
                  className={
                    tabSelected == 1
                      ? 'tab tab-lg tab-bordered tab-active font-bold'
                      : 'tab tab-lg tab-bordered'
                  }
                >
                  {t('labels.content')}
                </a>
                {!showInCustomerViewMode && (
                  <a
                    onClick={() => setTabSelected(2)}
                    className={
                      tabSelected == 2
                        ? 'tab tab-lg tab-bordered tab-active font-bold'
                        : 'tab tab-lg tab-bordered'
                    }
                  >
                    {t('labels.similarities')}
                  </a>
                )}
              </div>
            )}
          </div>

          {tabSelected == 2 && !isPDF && (
            <>
              {/* PROFILS: Profiles similaires LOOKALIKES */}
              {influencerReport.profile?.lookalikes &&
                influencerReport.profile?.lookalikes.length > 0 && (
                  <div className="my-4">
                    <div className="flex flex-wrap gap-4 my-4">
                      {influencerReport.profile.lookalikes.map(
                        (post, index) => {
                          return renderCardSimilarProfile(index, post);
                        }
                      )}
                    </div>
                  </div>
                )}

              {/* PROFILS: Profiles similaires LOOKALIKES BY TOPICS */}
              {influencerReport.profile?.lookalikesByTopics &&
                influencerReport.profile?.lookalikesByTopics.length > 0 && (
                  <div className="my-4">
                    <div className="flex flex-wrap gap-4 my-4">
                      {influencerReport.profile.lookalikesByTopics.map(
                        (post, index) => {
                          return renderCardSimilarProfile(index, post);
                        }
                      )}
                    </div>
                  </div>
                )}

              {/* No lookalikes? */}
              {!influencerReport.profile?.lookalikes?.length &&
                !influencerReport.profile?.lookalikesByTopics?.length && (
                  <div className="flex flex-col justify-center text-center my-16">
                    <img src={emptyFile} className="imgEmpty" alt="empty" />
                    <p>{t('report-no-similar')}</p>
                  </div>
                )}
            </>
          )}

          {tabSelected == 1 && !isPDF && (
            <>
              <FormInput
                type="select"
                onChange={(event) => {
                  setContentCategory(event.value.toString());
                  if (event.value === 'famous') {
                    setPostsToDisplay(
                      influencerReport?.profile?.popularPosts || []
                    );
                  } else if (event.value === 'recents') {
                    setPostsToDisplay(
                      influencerReport?.profile?.recentPosts || []
                    );
                  } else if (event.value === 'sponsored') {
                    setPostsToDisplay(
                      influencerReport?.profile?.sponsoredPosts || []
                    );
                  }
                }}
                name="influencer.age.max"
                className="my-4"
                value={contentCategory}
              >
                <option value={'famous'}>⭐️ {t('report-posts-famous')}</option>
                <option value={'recents'}>📅 {t('report-posts-recent')}</option>
                <option value={'sponsored'}>
                  💰 {t('report-posts-sponsored')}
                </option>
              </FormInput>

              {/* POSTS: Posts POPULAIRES */}
              {postsToDisplay && postsToDisplay.length > 0 && (
                <div className="my-4">
                  <div className="flex flex-wrap gap-4 my-4">
                    {postsToDisplay.map((post, index) => {
                      return renderCardPosts(index, post);
                    })}
                  </div>
                </div>
              )}
            </>
          )}

          {(tabSelected == 0 ? true : isPDF) && (
            <>
              {/* STATS: 4 key numbers */}
              <div className="my-4">
                <h4 className="text-xl font-bold">{t('report-key-numbers')}</h4>
                <div className="grid gap-4  my-4">
                  <div className="stats shadow">
                    <SimpleDataChart
                      title={`${formatNumber(
                        influencerReport.profile?.postsCount
                      )}`}
                      subTitle={t('labels.posts')}
                      icon={
                        <img
                          src={imgPicture}
                          width={24}
                          height={24}
                          alt="emoji post"
                        />
                      }
                      desc={t('report-total')}
                    />
                    <SimpleDataChart
                      title={`${formatNumber(
                        influencerReport.profile?.stats?.avgLikes?.value ||
                          influencerReport.profile?.avgLikes
                      )}`}
                      subTitle={t('labels.count-likes')}
                      icon={
                        <img
                          src={imgThumbUp}
                          width={24}
                          height={24}
                          alt="emoji thumbup"
                        />
                      }
                      desc={t('report-avg-by-post')}
                    />
                    <SimpleDataChart
                      title={`${formatNumber(
                        influencerReport.profile?.avgComments
                      )}`}
                      subTitle={t('labels.count-comments')}
                      icon={
                        <img
                          src={imgComment}
                          width={24}
                          height={24}
                          alt="emoji comment"
                        />
                      }
                      desc={t('report-avg-by-post')}
                    />
                    <SimpleDataChart
                      title={`${formatNumber(
                        influencerReport.profile?.avgReelsPlays ||
                          influencerReport.profile?.profile?.averageViews ||
                          0
                      )}`}
                      subTitle={t('labels.count-views')}
                      icon={
                        <img
                          src={imgPlay}
                          width={24}
                          height={24}
                          alt="emoji play"
                        />
                      }
                      desc={t('report-avg-by-video')}
                    />
                  </div>
                </div>
              </div>

              <div style={{ position: 'relative' }}>
                {/* CHARTS: gender & age rate */}
                <div
                  className={`grid gap-8 ${shouldBlur ? 'blur-bg' : ''}`}
                  style={{ gridTemplateColumns: isPDF ? '1fr 1.3fr' : '1fr 1fr'}}
                >
                  {/* Audience Gender */}
                  {influencerList?.settings?.onDetails?.showGender !== false &&
                    influencerReport?.profile?.audience?.genders.length > 0 && (
                      <div>
                        <h5 className="text-xl font-bold">
                          {t('labels.audience_gender')}
                        </h5>
                        <div className="flex flex-col my-4 items-center">
                          <Box align="center" pad="large">
                            <Stack anchor="center">
                              <Meter
                                type="circle"
                                background="white"
                                values={[
                                  {
                                    value:
                                      influencerReport?.profile.audience
                                        ?.genders[0].weight * 100,
                                    color:
                                      influencerReport?.profile.audience
                                        ?.genders[0].code === 'MALE'
                                        ? '#49a1f8'
                                        : '#db56a4',
                                  },
                                  {
                                    value:
                                      influencerReport?.profile.audience
                                        ?.genders[1].weight * 100,
                                    color:
                                      influencerReport?.profile.audience
                                        ?.genders[1].code === 'MALE'
                                        ? '#49a1f8'
                                        : '#db56a4',
                                  },
                                ]}
                                max={100}
                                size="small"
                                thickness="medium"
                              />
                              <Box align="center">
                                <Box
                                  direction="row"
                                  align="center"
                                  pad={{ bottom: 'xsmall' }}
                                >
                                  <Text size="xxlarge" weight="bold">
                                    {formatPercentage(
                                      influencerReport?.profile.audience?.genders.sort(
                                        (a, b) => b.weight - a.weight
                                      )[0].weight
                                    )}
                                  </Text>
                                  <Text>%</Text>
                                </Box>
                                <Text>
                                  {influencerReport?.profile.audience?.genders.sort(
                                    (a, b) => b.weight - a.weight
                                  )[0].code === 'MALE'
                                    ? t('labels.genders.male')
                                    : t('labels.genders.female')}
                                </Text>
                              </Box>
                            </Stack>
                          </Box>

                          <div className="flex flex-col items-start ">
                            {influencerReport?.profile.audience?.genders.map(
                              (gender) => {
                                return (
                                  <div
                                    className="flex gap-6 meter-caption"
                                    key={Math.random()}
                                  >
                                    <div className="meter-caption-left w-[120px]">
                                      <div
                                        className={`caption-indicator caption-indicator--${gender.code}`}
                                      >
                                        &nbsp;
                                      </div>
                                      <div>
                                        {gender.code === 'MALE'
                                          ? t('labels.genders.male')
                                          : t('labels.genders.female')}
                                      </div>
                                    </div>
                                    <strong>
                                      {formatPercentage(gender.weight)}%
                                    </strong>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                  {/* Audience Age Range */}
                  {influencerList?.settings?.onDetails?.showAge !== false &&
                    influencerReport?.profile?.audience?.gendersPerAge.length >
                      0 && (
                      <div className={`${shouldBlur ? 'blur-bg' : ''}`}>
                        <h5 className="text-xl font-bold">
                          {t('report-ages-range')}
                        </h5>
                        <div className="flex my-6">
                          <DataChart
                            data={(() => {
                              return influencerReport.profile?.audience?.gendersPerAge.map(
                                (g) => ({
                                  ...g,
                                  male: g.male * 100,
                                  female: g.female * 100,
                                  total: Math.round(
                                    g.male * 100 + g.female * 100
                                  ),
                                })
                              );
                            })()}
                            series={[
                              {
                                property: 'code',
                                render: (code) => (
                                  <>
                                    <Text
                                      margin={{ horizontal: 'xsmall' }}
                                      style={{
                                        fontSize: '0.875rem',
                                        lineHeight: '1.25rem',
                                        whiteSpace: 'nowrap',
                                      }}
                                      className={'text-sm'}
                                    >
                                      {code}
                                    </Text>
                                    <Text
                                      margin={{ horizontal: 'xsmall' }}
                                      style={{
                                        fontSize: '0.875rem',
                                        lineHeight: '1.25rem',
                                        whiteSpace: 'nowrap',
                                      }}
                                      className={'text-sm font-bold'}
                                    >
                                      {formatPercentage(
                                        (influencerReport.profile?.audience?.gendersPerAge.find(
                                          (g) => g.code === code
                                        )?.female || 0) +
                                          (influencerReport.profile?.audience?.gendersPerAge.find(
                                            (g) => g.code === code
                                          )?.male || 0)
                                      )}
                                      %
                                    </Text>
                                  </>
                                ),
                              },
                              'male',
                              'female',
                            ]}
                            chart={[
                              {
                                property: 'male',
                                type: 'bar',
                                thickness: 'small',
                                color: '#49a1f8',
                              },
                              {
                                property: 'female',
                                type: 'bar',
                                thickness: 'small',
                                color: '#db56a4',
                              },
                            ]}
                            offset={{ gap: 'xxsmall' }}
                            axis={{
                              x: { property: 'code', granularity: 'fine' },
                              y: { granularity: 'medium' },
                            }}
                            guide={{ y: true }}
                            legend={false}
                          />
                        </div>
                      </div>
                    )}
                </div>
                {shouldBlur && (
                  <div className="blur-cta stat-value">
                    {t('report-ask-unlock-1')} en&nbsp;
                    <a
                      className="font-bold text-primary"
                      href="mailto:info@clarkinfluence.com"
                    >
                      {t('report-ask-unlock-2')}
                    </a>{' '}
                    💌
                  </div>
                )}
              </div>

              {/* CHARTS: other & other */}
              <div
                className={`grid gap-8 ${shouldBlur ? 'blur-bg' : ''}`}
                style={{ gridTemplateColumns: '1fr 1fr' }}
              >
                {/* Countries */}
                {influencerList?.settings?.onDetails?.showCountry !== false &&
                  influencerReport?.profile?.audience?.geoCountries?.length >
                    0 && (
                    <div>
                      <h5 className="text-xl font-bold">
                        {t('report-top-countries')}
                      </h5>
                      <div className="flex my-6">
                        <DataChart
                          data={influencerReport.profile.audience?.geoCountries
                            .slice(0, 5)
                            .map((entry, i) => {
                              return {
                                audience: (
                                  <>
                                    <p>{formatPercentage(entry.weight)}%</p>
                                    <p className="text-sm self-center justify-center text-center">
                                      {countryCodeToFlag(entry.code)}
                                    </p>
                                  </>
                                ),
                                percent: formatPercentage(entry.weight),
                              };
                            })}
                          series={[
                            {
                              property: 'audience',
                            },
                            {
                              property: 'percent',
                              suffix: '%',
                            },
                          ]}
                          chart="percent"
                          axis={{
                            x: { property: 'audience', granularity: 'fine' },
                            y: { property: 'percent', granularity: 'medium' },
                          }}
                        />
                      </div>
                    </div>
                  )}
                {/* Cities */}
                {influencerList?.settings?.onDetails?.showCity !== false &&
                  influencerReport?.profile?.audience?.geoCities?.length >
                    0 && (
                    <div>
                      <h5 className="text-xl font-bold">
                        {t('report-top-cities')}
                      </h5>
                      <div className="flex my-6">
                        <DataChart
                          data={influencerReport.profile.audience?.geoCities
                            .slice(0, 5)
                            .map((entry, i) => {
                              return {
                                audience: (
                                  <>
                                    <p>{formatPercentage(entry.weight)}%</p>
                                    <p className="text-sm self-center justify-center text-center font-bold">
                                      {entry?.name}
                                    </p>
                                  </>
                                ),
                                percent: formatPercentage(entry.weight),
                              };
                            })}
                          series={[
                            'audience',
                            { property: 'percent', suffix: '%' },
                          ]}
                          chart="percent"
                          axis={{
                            x: { property: 'audience', granularity: 'fine' },
                            y: { property: 'percent', granularity: 'fine' },
                          }}
                        />
                      </div>
                    </div>
                  )}
              </div>

              {/* CHARTS: Ethnicities */}
              <div
                className={`grid gap-8 ${shouldBlur ? 'blur-bg' : ''}`}
                style={{ gridTemplateColumns: '1fr 1fr' }}
              >
                {/* Followers Ethnicities */}
                {influencerReport?.profile?.audience?.ethnicities &&
                  influencerReport?.profile?.audience?.ethnicities.length >
                    0 && (
                    <div style={{ width: '100%', height: '100%' }}>
                      <h5 className="text-xl font-bold">
                        {t('report-etnical-origins')}
                      </h5>
                      <div className="flex my-6">
                        <DataChart
                          data={influencerReport.profile.audience?.ethnicities.map(
                            (entry, i) => {
                              return {
                                audience: (
                                  <>
                                    <p>
                                      {formatNumber(
                                        influencerReport.profile.profile
                                          .followers * entry.weight
                                      )}
                                    </p>
                                    <p>{formatPercentage(entry.weight)}%</p>
                                    <p className="text-sm self-center justify-center text-center font-bold">
                                      {entry?.name}
                                    </p>
                                  </>
                                ),
                                percent: formatPercentage(entry.weight),
                              };
                            }
                          )}
                          gap={'small'}
                          series={[
                            'audience',
                            { property: 'percent', suffix: '%' },
                          ]}
                          chart="percent"
                          axis={{
                            x: { property: 'audience', granularity: 'fine' },
                            y: { property: 'percent', granularity: 'fine' },
                          }}
                        />
                      </div>
                    </div>
                  )}

                {/* Likers Ethnicities */}
                {influencerReport?.profile?.audienceLikers?.ethnicities &&
                  influencerReport?.profile?.audienceLikers?.ethnicities
                    .length > 0 && (
                    <div style={{ width: '100%', height: '100%' }}>
                      <h5 className="text-xl font-bold">
                        {t('report-etnical-origins-likes')}
                      </h5>
                      <div className="flex my-6">
                        <DataChart
                          data={influencerReport.profile.audienceLikers.ethnicities.map(
                            (entry, i) => {
                              return {
                                audience: (
                                  <>
                                    <p>
                                      {formatNumber(
                                        influencerReport.profile.profile
                                          .followers * entry.weight
                                      )}
                                    </p>
                                    <p>{formatPercentage(entry.weight)}%</p>
                                    <p className="text-sm self-center justify-center text-center font-bold">
                                      {entry?.name}
                                    </p>
                                  </>
                                ),
                                percent: formatPercentage(entry.weight),
                              };
                            }
                          )}
                          gap={'small'}
                          series={[
                            'audience',
                            { property: 'percent', suffix: '%' },
                          ]}
                          chart="percent"
                          axis={{
                            x: { property: 'audience', granularity: 'fine' },
                            y: { property: 'percent', granularity: 'fine' },
                          }}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  if (loading)
    return (
      <div>
        <dialog id={name} className="modal">
          <form method="dialog" className="modal-box flex justify-center">
            <Loading />
          </form>

          <form method="dialog" className="modal-backdrop">
            <button>{t('labels.close')}</button>
          </form>
        </dialog>
      </div>
    );

  if (!influencerReport)
    return (
      <div className="flex flex-col justify-center text-center">
        <img src={emptyBox} className="imgEmpty mb-8" alt="empty" />
        <p>{t('report-not-found-influencer')}</p>
      </div>
    );

  if (showInModal) {
    return (
      <div>
        <dialog id={name} className="modal">
          <form method="dialog" className="modal-box w-11/12 max-w-4xl">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>

            {renderInfluencerData(influencerReport)}
          </form>

          <form method="dialog" className="modal-backdrop">
            <button>{t('labels.close')}</button>
          </form>
        </dialog>
      </div>
    );
  } else {
    return <>{renderInfluencerData(influencerReport)}</>;
  }
}
